<template>
  <div class="content">
    <div class="box">
      <div class="tableTitle">
        <span class="title">销售部审核意见</span>
      </div>
      <div class="fromDiv">
        <div class="fullInput">
          <i-input v-model="form.sale_opinion" :rows="4" :disabled="judg1" type="textarea" placeholder="请输入..."> </i-input>
        </div>
        <div class="formLine borderTopNone">
          <FromLabel label="负责人签字" :showIcon="true"></FromLabel>
          <div class="formFlIpt647 fl">
            <i-input v-model="form.sale_person" :disabled="judg1" placeholder="请输入" class="iviewIptWidth307 marginRight20"></i-input>
            <RadioGroup v-model="form.sale_state">
              <Radio class="marginRight20" :disabled="judg1" label="1">同意</Radio>
              <Radio label="0" :disabled="judg1">驳回</Radio>
            </RadioGroup>
          </div>
          <FromLabel label="签字日期" :showIcon="true"></FromLabel>
          <div class="formFrIpt fl">
            <DatePicker :editable="false" :disabled="judg1" type="datetime" placeholder="请选择" class="iviewIptWidth307" :value="form.sale_date" @on-open-change="openChange($event, 'sale_date')" @on-change="changeTime($event, 'sale_date')"></DatePicker>
          </div>
        </div>
      </div>

      <div class="tableTitle">
        <span class="title">质量管理部审核意见</span>
      </div>
      <div class="fromDiv">
        <div class="fullInput">
          <i-input v-model="form.quality_opinion" :disabled="judg2" :rows="4" type="textarea" placeholder="请输入..."> </i-input>
        </div>
        <div class="formLine borderTopNone">
          <FromLabel label="负责人签字" :showIcon="true"></FromLabel>
          <div class="formFlIpt647 fl">
            <i-input v-model="form.qualityn_person" :disabled="judg2" placeholder="请输入" class="iviewIptWidth307 marginRight20"></i-input>
            <RadioGroup v-model="form.quality_state">
              <Radio class="marginRight20" :disabled="judg2" label="1">同意</Radio>
              <Radio label="0" :disabled="judg2">驳回</Radio>
            </RadioGroup>
          </div>
          <FromLabel label="签字日期" :showIcon="true"></FromLabel>
          <div class="formFrIpt fl">
            <DatePicker :editable="false" :disabled="judg2" type="datetime" placeholder="请选择" class="iviewIptWidth307" :value="form.quality_date" @on-open-change="openChange($event, 'quality_date')" @on-change="changeTime($event, 'quality_date')"></DatePicker>
          </div>
        </div>
      </div>
      <div class="tableTitle">
        <span class="title"> 总经理意见 </span>
      </div>
      <div class="fromDiv">
        <div class="fullInput">
          <i-input v-model="form.admin_opinion" :disabled="judg3" :rows="4" type="textarea" placeholder="请输入..."> </i-input>
        </div>
        <div class="formLine borderTopNone">
          <FromLabel label="负责人签字" :showIcon="true"></FromLabel>
          <div class="formFlIpt647 fl">
            <i-input v-model="form.admin_person" :disabled="judg3" placeholder="请输入" class="iviewIptWidth307 marginRight20"></i-input>
            <RadioGroup v-model="form.admin_state">
              <Radio class="marginRight20" :disabled="judg3" label="1">同意</Radio>
              <Radio label="0" :disabled="judg3">驳回</Radio>
            </RadioGroup>
          </div>
          <FromLabel label="签字日期" :showIcon="true"></FromLabel>
          <div class="formFrIpt fl">
            <DatePicker :editable="false" :disabled="judg3" type="datetime" placeholder="请选择" class="iviewIptWidth307" :value="form.admin_date" @on-open-change="openChange($event, 'admin_date')" @on-change="changeTime($event, 'admin_date')"></DatePicker>
          </div>
        </div>
      </div>
      <div class="saveDiv clearfix">
        <div class="pageBtn finger btnSure fr marginLeft20" v-show="roleId == 1" @click="gothrougth()">一键通过</div>
        <div class="pageBtn finger btnSure fr marginLeft20" @click="newNextStep()">保存</div>
        <span class="pageBtn finger btnReset fr marginLeft20" @click="back">返回</span>
      </div>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
export default {
  name: 'clientRegisteCerticate',
  components: {
    FromLabel,
  },
  props: ['hospitalid'],
  data() {
    return {
      passFlag: false,
      type: '',
      roleId: null,
      license: [],
      isLoad: false,
      imgList: [{ src: '' }, { src: '' }],
      typeList: [1],
      chooseGroup: '驳回',

      // 表单
      form: {
        hospital_id: null, // 医院ID
        quality_opinion: null, //	质量管理部审核意见
        qualityn_person: null, // 质量管理部审核负责人
        quality_state: null, // 质量管理部审核状态 0=驳回 1=同意
        quality_date: null, // 质量管理部审核签字日期 日期格式”0000-00-00“没填不传
        sale_opinion: null, // 销售部审核意见
        sale_person: null, //	销售部审核负责人
        sale_state: null, // 销售部审核状态 0=驳回 1=同意
        sale_date: null, // 销售部审核签字日期 日期格式”0000-00-00“没填不传
        admin_opinion: null, // 总经理或管理者代表意见
        admin_person: null, //	总经理或管理者代表负责人
        admin_state: null, // 总经理或管理者代表状态 0=驳回 1=同意
        admin_date: null, // 总经理或管理者代表签字日期 日期格式”0000-00-00“没填不传
      },
      judg1: true,
      judg2: true,
      judg3: true,
      judg4: true,
    }
  },
  created() {},
  mounted() {
    this.roleId = window.localStorage.getItem('userRole')
    this.judgement()
    this.$root.eventHub.$off('sendFistApprovalId')
    this.$root.eventHub.$on('sendFistApprovalId', firstApprovalId => {
      this.form.first_approval_id = firstApprovalId
      this.judge()
    })
    this.$root.eventHub.$off('sendhospitalID3')
    this.$root.eventHub.$on('sendhospitalID3', hospital_id => {
      this.hospital_id = hospital_id
      console.log(this.hospital_id, '第四个')
    })
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    // 如果是点击名称进入就开发

    openChange(e, name) {
      if (!this.form[name]) {
        this.form[name] = this.$moment().format('YYYY-MM-DD HH:mm:ss')
      }
    },
    // 时间改变
    changeTime(e, name) {
      console.log(e, 'e')
      this.form[name] = e
    },

    // 判断进进入的身份 给予权限执行操作
    judgement() {
      // 如果是销售主管和 销售员
      if (this.roleId == 5 || this.roleId == 6) {
        this.judg1 = false
        this.judg2 = true
        this.judg3 = true
      }
      // 如果是质量管理员
      if (this.roleId == 8) {
        this.judg1 = true
        this.judg2 = false
        this.judg3 = true
      }

      // 总经理 或者超级管理员
      if (this.roleId == 2 || this.roleId == 1) {
        this.judg1 = false
        this.judg2 = false
        this.judg3 = false
      }
    },

    // 开始判断状态  是新增进入 还是直接进入
    judge() {
      if (this.$route.query.id !== undefined) {
        this.getApprovalInfo()
      } else {
        console.log('新增进入')
      }
    },

    changePage(e) {},

    // 获取审核信息
    getApprovalInfo() {
      let data = {
        hospital_id: this.$route.query.id,
        first_approval_id: this.form.first_approval_id,
      }

      if (data.first_approval_id != null) {
        this.$http.get(this.$api.hospitalManageApproval, data, true).then(res => {
          console.log(res)
          this.form = res.data
          // 处理事件
          this.form.admin_date = this.$moment.unix(res.data.admin_date).format('YYYY-MM-DD HH:mm:ss')
          this.form.quality_date = this.$moment.unix(res.data.quality_date).format('YYYY-MM-DD HH:mm:ss')
          this.form.sale_date = this.$moment.unix(res.data.sale_date).format('YYYY-MM-DD HH:mm:ss')
        })
      }
    },
    // 一次性保存四个页面
    async gothrougthSave() {
      // 那么也就是说，需要调用四个页面的保存按钮
      let that = this
      let status1 = await this.$parent.$refs.ClientDealerInfo.nextstep(true)
      let status2 = await this.$parent.$refs.ClientRegisteCerticate.nextstep(true)
      let status3 = await this.$parent.$refs.ClientOtherMsg.nextstep(true)
      if (status1 && status2 && status3) {
        // this.$router.push('/clientManage')
        this.$Message.success('保存成功')
      }
    },
    async gothrougthSave2() {
      // 那么也就是说，需要调用四个页面的保存按钮
      let that = this
      let status1 = await this.$parent.$refs.ClientDealerInfo.nextstep(true)
      let status2 = await this.$parent.$refs.ClientRegisteCerticate.nextstep(true)
      let status3 = await this.$parent.$refs.ClientOtherMsg.nextstep(true)
      let status4 = await this.save(true)
      if (status1 && status2 && status3 && status4) {
        this.$router.push('/clientManage')
        this.$Message.success('保存成功')
      }
    },
    // 一键同意触发事件
    gothrougth() {
      this.$parent.$refs.ClientDealerInfo.nextstep(true)
      this.$parent.$refs.ClientRegisteCerticate.nextstep(true)
      this.$parent.$refs.ClientOtherMsg.nextstep(true)
      this.$http.get(this.$api.CustomerOnePass, false).then(res => {
        this.form = res.data
        this.form.admin_date = this.$moment.unix(res.data.admin_date).format('YYYY-MM-DD HH:mm:ss')
        this.form.quality_date = this.$moment.unix(res.data.quality_date).format('YYYY-MM-DD HH:mm:ss')
        this.form.sale_date = this.$moment.unix(res.data.sale_date).format('YYYY-MM-DD HH:mm:ss')
        this.form.hospital_id = this.$route.query.id || this.hospital_id
        this.$http.post(this.$api.hospitalManageApproval, this.form, true).then(res => {
          console.log(that.passFlag == true, 'that.passFlag == true')
          if (that.passFlag == true) {
            this.$router.push('/clientManage')
            this.$Message.success('保存成功')
          }
        })
      })
      sessionStorage.setItem('updataCache', '0')
    },
    // 保存事件
    newNextStep() {
      this.$parent.$refs.ClientProductApproval.gothrougthSave2()
    },
    async save(type) {
      // 保存
      let status1 = false
      this.form.hospital_id = this.$route.query.id || this.hospital_id
      let data = this.form
      console.log(data)
      if (this.roleId == '5' || this.roleId == '6') {
        if (!data.sale_opinion) {
          this.$Message.warning('请输入首营审批页面的销售部审核意见')
          return
        }
        if (!data.sale_person) {
          this.$Message.warning('请输入首营审批页面的销售部负责人')
          return
        }
        if (!data.sale_state) {
          this.$Message.warning('请填写首营审批页面的销售部同意或驳回选项')
          return
        }
        if (!data.sale_date) {
          this.$Message.warning('请输入首营审批页面的销售部签字日期')
          return
        }
      }

      if (this.roleId == '8') {
        if (!data.quality_opinion) {
          this.$Message.warning('请输入首营审批页面的质量管理部审核意见')
          return
        }
        if (!data.qualityn_person) {
          this.$Message.warning('请输入首营审批页面的质量管理部负责人')
          return
        }
        if (!data.quality_state) {
          this.$Message.warning('请填写首营审批页面的质量管理部同意或驳回选项')
          return
        }
        if (!data.quality_date) {
          this.$Message.warning('请输入首营审批页面的质量管理部签字日期')
          return
        }
      }

      if (this.roleId == '2') {
        if (!data.quality_opinion) {
          this.$Message.warning('请输入首营审批页面的总经理审核意见')
          return
        }
        if (!data.qualityn_person) {
          this.$Message.warning('请输入首营审批页面的总经理负责人')
          return
        }
        if (!data.quality_state) {
          this.$Message.warning('请填写首营审批页面的总经理同意或驳回选项')
          return
        }
        if (!data.quality_date) {
          this.$Message.warning('请输入首营审批页面的总经理签字日期')
          return
        }
      }
      if (this.roleId == '1') {
        if (!data.sale_opinion) {
          this.$Message.warning('请输入首营审批页面的销售部审核意见')
          return
        }
        if (!data.sale_person) {
          this.$Message.warning('请输入首营审批页面的销售部负责人')
          return
        }
        if (!data.sale_state) {
          this.$Message.warning('请填写首营审批页面的销售部同意或驳回选项')
          return
        }
        if (!data.sale_date) {
          this.$Message.warning('请输入首营审批页面的销售部签字日期')
          return
        }

        if (!data.quality_opinion) {
          this.$Message.warning('请输入首营审批页面的质量管理部审核意见')
          return
        }
        if (!data.qualityn_person) {
          this.$Message.warning('请输入首营审批页面的质量管理部负责人')
          return
        }
        if (!data.quality_state) {
          this.$Message.warning('请填写首营审批页面的质量管理部同意或驳回选项')
          return
        }
        if (!data.quality_date) {
          this.$Message.warning('请输入首营审批页面的质量管理部签字日期')
          return
        }

        if (!data.quality_opinion) {
          this.$Message.warning('请输入首营审批页面的总经理审核意见')
          return
        }
        if (!data.qualityn_person) {
          this.$Message.warning('请输入首营审批页面的总经理负责人')
          return
        }
        if (!data.quality_state) {
          this.$Message.warning('请填写首营审批页面的总经理同意或驳回选项')
          return
        }
        if (!data.quality_date) {
          this.$Message.warning('请输入首营审批页面的总经理签字日期')
          return
        }
      }
      status1 = await this.$http
        .post(this.$api.hospitalManageApproval, data, true)
        .then(res => {
          if (!type) {
            this.$router.push('/clientManage')
            this.$Message.success('保存成功')
          }
          return true
        })
        .catch(err => {
          return false
        })
      sessionStorage.setItem('updataCache', '0')
      return status1
    },
  },
}
</script>

<style scoped lang="less">
.marginRight20px {
  margin-right: 20px;
}
.content {
  height: 100%;
  .box {
    height: 100%;
    position: relative;
    .saveDiv {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.tableTitle {
  margin: 10px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.centerUnit {
  margin: 0px 10px 0px 30px !important;
}
.imgBox {
  min-height: 110px;
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    li {
      position: relative;
      margin-right: 18px;
      display: inline-block;
      height: 102px;
      width: 171px;
      background-color: #d8d8d8;
      .normalImg {
        height: 100%;
        width: 100%;
      }
      .deleteImg {
        height: 22px;
        width: 22px;
        position: absolute;
        right: -11px;
        top: -11px;
        cursor: pointer;
      }
    }
  }
}
.addImg,
.removeImg {
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.addImg {
  margin: 0 14px 0 36px;
}
.afterWords10 {
  margin-left: 10px;
  color: #525b6d;
}
.marginWords10,
.singleCheckBox {
  color: #525b6d;
  margin: 0 10px;
}
.marginRight20 {
  margin-right: 20px;
}
/deep/ .fullInput {
  width: 100%;
  height: 85px;
  padding: 7px;
  box-sizing: border-box;
  border: 1px solid #e8eaec;
  .ivu-input-wrapper {
    height: 100% !important;
  }
  textarea.ivu-input {
    height: 100% !important;
  }
}
</style>
