<template>
  <div class="content">
    <!--  基础信息 -->
    <div class="fromDiv">
      <div class="tableTitle">
        <span class="title"> 客户信息 </span>
      </div>
      <div class="formLine">
        <FromLabel label="营业执照"></FromLabel>
        <div class="formFlIpt578 fl">
          <Input readonly v-model="form.business_license_name" class="iviewIptWidth307" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件">
            <span slot="append" class="finger">
              <FormUpload label="上传"  @get-key="getKey"></FormUpload>
            </span>
          </Input>
          <span class="lookupImg nowrap" @click="seeBusinesLicence">{{ form.business_license_name }}</span>
        </div>
        <FromLabel label="所属区域"></FromLabel>
        <div class="formFrIpt fl">
          <Select @on-change="chooseProvince" v-model="form.belong_province" class="iviewIptWidth165">
            <Option v-for="(item, index) in provinceList" :value="item.area_code" :key="index">{{ item.area_name }}</Option>
          </Select>
          <Select @on-change="chooseCity" v-model="form.belong_city" class="iviewIptWidth165 marginLeft20">
            <Option v-for="(item, index) in cityList" :value="item.area_code" :key="index">{{ item.area_name }}</Option>
          </Select>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="客户名称" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <Input :disabled="Boolean(form.is_approved)" v-model="form.hospital_name" placeholder="请输入客户名称" class="iviewIptWidth307" />
        </div>
        <FromLabel label="统一社会信用代码" :showIcon="true"></FromLabel>
        <div class="formFrIpt">
          <Input type="text" maxlength="18" v-model="form.social_credit_code" placeholder="请输入" class="iviewIptWidth307" />
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="类型"></FromLabel>
        <div class="allWidth">
          <Input v-model="form.type" placeholder="请输入" class="iviewIptWidth307" />
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="医院地址"></FromLabel>
        <div class="allWidth">
          <!--          <Select @on-change="chooseCompanyProvince" v-model="form.company_province" class="iviewIptWidth165">-->
          <!--            <Option v-for="(item, index) in companyprovinceList" :key="index" :value="item.area_code">{{ item.area_name }}</Option>-->
          <!--          </Select>-->
          <!--          &lt;!&ndash; 告诉后端 这个地址有两个 一个公司市区地址  还有一个填写详细地址   &ndash;&gt;-->
          <!--          <Select @on-change="chooseCompanyCity" v-model="form.company_city" class="iviewIptWidth165 marginLeft20">-->
          <!--            <Option v-for="(item, index) in companyCityList" :key="index" :value="item.area_code">{{ item.area_name }}</Option>-->
          <!--          </Select>-->
          <i-input v-model="form.company_detailed_address" placeholder="请输入医院详情地址" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel :showIcon="true" label="法人"></FromLabel>
        <div class="allWidth">
          <i-input v-model="form.legal_person" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="成立日期"></FromLabel>
        <div class="formFlIpt578 fl">
          <DatePicker
            @on-change="
              e => {
                this.form.setup_date = e
              }
            "
            :value="form.setup_date"
            format="yyyy-MM-dd"
            type="date"
            placeholder="请选择"
            :options="setupDateOptions"
            class="iviewIptWidth307"
            :transfer="true"
          ></DatePicker>
        </div>
        <FromLabel label="注册资本"></FromLabel>
        <div class="formFrIpt fl">
          <i-input type="number" min="1" v-model="form.registered_capital" placeholder="请输入" class="iviewIptWidth307" @on-blur="iptChange"></i-input>
          <span class="afterWords10"> 万元</span>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel :showIcon="true" label="营业期限"></FromLabel>
        <div class="formFlIpt578 fl">
          <DatePicker class="iviewIptWidth165" @on-open-change="setTimet" :options="options2" @on-change="changeTime($event, 1)" :value="form.business_start" format="yyyy-MM-dd" type="date" placeholder="请选择"></DatePicker>
          <span class="marginWords10"> 至 </span>
          <DatePicker class="iviewIptWidth165" @on-change="changeTime($event, 2)" :options="options" :value="form.business_end" :disabled="longTerm" format="yyyy-MM-dd" type="date" placeholder="请选择"></DatePicker>
          <Checkbox true-value="1" false-value="0" v-model="form.is_long_term" @on-change="LongTermChange" class="singleCheckBox"><span class="fontSize14px">长期</span></Checkbox>
        </div>
        <FromLabel :showIcon="true" label="登记日期"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker
            @on-change="
              e => {
                this.form.register_date = e
              }
            "
            @on-open-change="setTimet"
            :options="options2"
            :value="form.register_date"
            format="yyyy-MM-dd"
            type="date"
            placeholder="请选择"
            class="iviewIptWidth307"
          ></DatePicker>
        </div>
      </div>
      <div class="formLine borderTopNone moreHeight">
        <div class="newformLabel fl">
          <div>
            <span class="icon">*</span>
            <span>经营范围</span>
          </div>
        </div>
        <div class="allWidth">
          <i-input :disabled="false" type="textarea" maxlength="1000" show-word-limit :autosize="{ minRows: 2, maxRows: 2 }" v-model="form.business_range" placeholder="请输入经营范围" class="iviewIptWidth1165px"></i-input>
        </div>
      </div>
      <div class="saveDiv clearfix">
        <!-- <div class="pageBtn finger btnSure fr marginLeft20" @click="newNextStep()">保存</div> -->
        <div class="pageBtn finger btnSure fr marginLeft20" @click="newNextStep()">保存</div>
        <span class="pageBtn finger btnReset fr marginLeft20" @click="back">返回</span>
      </div>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import FormUpload from '../../../components/formUpload'
export default {
  name: 'clientDealerInfo',
  components: {
    FromLabel,
    FormUpload,
  },
  data() {
    return {
      diffsave: '2',
      setupDateOptions: {},
      type: '',
      license: [],
      isLoad: false,
      // 控制上传对话框的现实
      imgStatus: false,
      // 控制 营业期限是否可选择
      longTerm: false,
      // 图片地址
      imgUrl: '',
      imgList: [{ src: '' }, { src: '' }],
      typeList: [1],
      single: '0',
      hospital_id: this.$route.query.id,

      // 新增客户列表存放
      form: {
        hospital_name: null, // 客户名称
        business_license_name: null, // 营业执照名称
        business_license_key: null, // 营业执照key
        business_license_url: null,
        type: null, //		类型 1=医院 2=经销商
        social_credit_code: null, //	统一社会信用代码
        belong_city: null, // 所属区域地址ID
        belong_province: null, //	所属区域省级ID
        company_detailed_address: null, // 公司详情地址
        legal_person: null, // 法人
        setup_date: null, // 成立日期
        registered_capital: 0, //	注册资本
        register_date: null, // 登记日期
        business_start: null, //	起始营业期限
        business_end: null, // 截止营业期限
        business_range: null, //	经营范围
        is_long_term: '0', //	是否长期 0=否 1=是
      },

      // 省列表
      provinceList: [],
      // 市列表
      cityList: [],
      // 省
      province: '',
      // 市
      city: '',
      // 公司省份
      companyProvince: '',
      // 公司市区
      companyCity: '',
      // 公司省份列表
      companyprovinceList: [],
      // 公司市区列表
      companyCityList: [],
      options: {},
      options2: {},
    }
  },
  created() {},
  mounted() {
    let that = this
    that.getBelongArea()
    that.judge()
    that.setupDateOptions = {
      disabledDate(date) {
        return date && date.valueOf() > new Date(that.$moment().format('YYYY-MM-DD'))
      },
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    iptChange() {
      this.form.registered_capital = this.$utils.onlyNumFormat(this.form.registered_capital)
    },
    setTimet() {
      let that = this
      that.options2 = {
        disabledDate(date) {
          return date && date.valueOf() > new Date(that.$moment().format('YYYY-MM-DD'))
        },
      }
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.form.business_start = e
        that.form.business_end = null
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.form.business_start)
          },
        }
      } else {
        this.form.business_end = e
      }
    },
    // 查看营业执照
    seeBusinesLicence() {
      window.open(this.form.business_license_url)
    },
    // 上传按钮触发事件
    getKey(key, name, url) {
      this.form.business_license_key = key
      this.form.business_license_name = name
      this.form.business_license_url = url
    },
    // 对话框打开事件
    ok() {
      console.log('ok')
    },
    // 关闭对话框事件
    cancel() {
      console.log('cancel')
    },
    // 开始判断状态  是新增进入 还是直接进入
    judge() {
      if (this.hospital_id !== undefined) {
        this.getCustomerMsg()
        console.log(this.hospital_id)
      } else {
        console.log('新增进入')
      }
    },
    // 获取客户信息
    getCustomerMsg() {
      let data = {
        hospital_id: this.hospital_id,
      }
      // 请求获取医院信息
      this.$http.get(this.$api.hospitalManageHospitalInformation, data, true).then(res => {
        console.log(res)
        // 将值赋予 form
        this.form = res.data
        if (this.form.is_long_term == '1') {
          this.longTerm = true
        } else {
          this.longTerm = false
        }
        if (this.form.registered_capital == '') {
          this.form.registered_capital = 0
        } else {
          this.form.registered_capital = Number(this.form.registered_capital)
        }
        // 通过事件 中心传递数值
        this.$root.eventHub.$emit('sendLicensingId', res.data.licensing_id)
        this.$root.eventHub.$emit('sendSupplyId', res.data.supply_id)
        this.$root.eventHub.$emit('sendFistApprovalId', res.data.first_approval_id)

        // 修改时间
        this.form.business_start = this.$moment.unix(res.data.business_start).format('YYYY-MM-DD')
        this.form.business_end = this.$moment.unix(res.data.business_end).format('YYYY-MM-DD')
        this.form.setup_date = this.$moment.unix(res.data.setup_date).format('YYYY-MM-DD')
        this.form.register_date = this.$moment.unix(res.data.register_date).format('YYYY-MM-DD')

        console.log(this.form)

        if (this.form.business_start == '1970-01-01') {
          this.form.business_start = null
        }
        if (this.form.business_end == '1970-01-01') {
          this.form.business_end = null
        }
        if (this.form.setup_date == '1970-01-01') {
          this.form.setup_date = null
        }
        if (this.form.register_date == '1970-01-01') {
          this.form.register_date = null
        }

        console.log(this.form)
        // 获取所属区域市区
        let data2 = {
          parent_id: this.form.belong_province,
        }
        if (data2.parent_id != 0 && data2.parent_id != null) {
          this.$http.get(this.$api.zoneList, data2, true).then(res => {
            this.cityList = res.data
          })
        }
        // // 获取公司市区
        let data3 = {
          parent_id: this.form.company_province,
        }
        if (data3.parent_id != 0 && data3.parent_id != null) {
          this.$http.get(this.$api.zoneList, data3, true).then(res => {
            this.companyCityList = res.data
          })
        }
      })
    },
    changePage(e) {},
    // 点击长期触发实现
    LongTermChange(e) {
      this.longTerm = !this.longTerm
      this.form.business_end = ''
    },
    // 点击下一步触发事件
    newNextStep() {
      this.diffsave = window.localStorage.getItem('diffsave')
      if (this.diffsave == '1') {
        this.nextstep()
      } else {
        this.$parent.$refs.ClientProductApproval.gothrougthSave()
      }
    },
    async nextstep(type) {
      let status1 = false
      // ---------添加判断条件--------
      if (!this.form.hospital_name || !this.form.hospital_name) {
        this.$Message.warning('请填写客户信息页面的客户名称')
        return
      }
      if (!this.form.social_credit_code || !this.form.social_credit_code) {
        this.$Message.warning('请填写客户信息页面的统一社会信用代码')
        return
      }
      if (!this.form.legal_person || !this.form.legal_person) {
        this.$Message.warning('请填写客户信息页面的法人')
        return
      }

      // 如果长期
      if (this.longTerm) {
        this.form.is_long_term = '1'
        if (this.form.business_start == '') {
          this.$Message.warning('请填写客户信息页面的起始营业期限')
          return
        }
      }

      // 如果不长期
      if (!this.longTerm) {
        this.form.is_long_term = '0'
        if (!this.form.business_start) {
          this.$Message.warning('请填写客户信息页面的起始营业期限')
          return
        }
        if (!this.form.business_end) {
          this.$Message.warning('请填写客户信息页面的截止营业期限')
          return
        }
      }
      if (!this.form.register_date) {
        this.$Message.warning('请填写客户信息页面的登记日期')
        return
      }
      if (!this.form.business_range || !this.form.business_range) {
        this.$Message.warning('请填写客户信息页面的经营范围')
        return
      }
      // ---------添加判断条件--------

      let data = this.form
      if (this.$route.query.id) {
        data.hospital_id = parseInt(this.$route.query.id)
      } else {
        this.$root.eventHub.$on('sendhospitalID', val => {
          data.hospital_id = val
        })
      }
      // data.business_start = this.$moment.unix(data.business_start).format('YYYY-MM-DD')
      // data.business_end = this.$moment.unix(data.business_end).format('YYYY-MM-DD')
      // data.setup_date = this.$moment.unix(data.setup_date).format('YYYY-MM-DD')
      // data.register_date = this.$moment.unix(data.register_date).format('YYYY-MM-DD')
      console.log(data)
      // --------时间处理 删除属性------
      if (!data.setup_date) {
        delete data.setup_date
      }
      if (!data.business_start) {
        delete data.business_start
      }
      if (!data.business_end) {
        delete data.business_end
      }
      if (!data.register_date) {
        delete data.register_date
      }

      // --------时间处理 删除属性-----
      console.log(this.form.is_long_term)
      if (this.form.is_long_term == null) {
      }

      // -------正式发送请求
      status1 = await this.$http
        .post(this.$api.hospitalManage, data, true)
        .then(res => {
          this.$root.eventHub.$emit('sendhospitalID', res.data.hospital_id)
          this.$root.eventHub.$emit('sendhospitalID2', res.data.hospital_id)
          this.$root.eventHub.$emit('sendhospitalID3', res.data.hospital_id)
          if (!type) {
            this.$emit('sendform', 2)
            window.localStorage.setItem('diffsave', '2')
            this.$Message.success('保存成功')
            // this.$router.push('/clientManage')
          }
          return true
        })
        .catch(err => {
          return false
        })
      sessionStorage.setItem('updataCache', '0')
      return status1
      // -------正式发送请求
    },

    // 获取所属区域
    getBelongArea() {
      let data = {
        parent_id: '0',
      }
      this.$http.get(this.$api.zoneList, data, true).then(res => {
        this.provinceList = res.data
        this.companyprovinceList = res.data
      })
    },
    // 选择所属区域省份触发
    chooseProvince(e) {
      let data = {
        // 传递数字
        parent_id: this.form.belong_province,
      }
      console.log(data)
      this.$http.get(this.$api.zoneList, data, false).then(res => {
        this.cityList = res.data
        this.form.belong_city = 0
      })
    },

    // 选择市区触发
    chooseCity(e) {
      console.log(e)
      this.form.belong_city = e
    },

    // 选择公司省份
    chooseCompanyProvince(e) {
      let data = {
        parent_id: this.form.company_province,
      }
      this.form.company_city = 0
      this.$http.get(this.$api.zoneList, data, false).then(res => {
        this.companyCityList = res.data
      })
    },
    chooseCompanyCity(e) {
      this.form.company_city = e
    },
  },
  watch: {},
}
</script>

<style scoped lang="less">
.content {
  height: 100%;
  .fromDiv {
    height: 100%;
    position: relative;
    .saveDiv {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
.fontSize14px {
  font-size: 14px;
}
.singleCheckBox {
  margin-left: 20px;
}
.marginWords10 {
  margin: 0 10px;
}
.iviewIptWidth1165px {
  width: 1165px;
}
.imgBox {
  width: 200px;
  height: 200px;
  img {
    width: 100%;
    height: 100%;
  }
}
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}

.tableTitle {
  margin: 17px 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}

.imgBox {
  min-height: 110px;
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    li {
      position: relative;
      margin-right: 18px;
      display: inline-block;
      height: 102px;
      width: 171px;
      background-color: #d8d8d8;
      .normalImg {
        height: 100%;
        width: 100%;
      }
      .deleteImg {
        height: 22px;
        width: 22px;
        position: absolute;
        right: -11px;
        top: -11px;
        cursor: pointer;
      }
    }
  }
}
.addImg,
.removeImg {
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.addImg {
  margin: 0 14px 0 36px;
}

.moreHeight {
  height: 100px;
}
.icon {
  font-size: 20px;
  height: 8px;
  width: 8px;
  position: absolute;
  left: 14px;
  top: 40px;
  color: #f73333;
}
.newformLabel {
  width: 187px;
  height: 100px;
  padding-left: 30px;
  background: #f8f8f9;
  border-right: 1px solid #e8eaec;
  position: relative;
  color: #525b6d;
  display: flex;
  align-items: center;
}
</style>
