<template>
  <div class="content">
    <!--  注册证 -->
    <div class="fromDiv">
      <div class="tableTitle">
        <span class="title"> 执业许可证 </span>
      </div>
      <div class="formLine">
        <FromLabel label="执业证许可证"></FromLabel>
        <div class="allWidth">
          <!-- 职业许可证名称  -->
          <Input readonly v-model="form.licensing_name" class="iviewIptWidth307" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件">
            <span slot="append" class="finger">
              <FormUpload label="上传" @get-key="getKey"></FormUpload>
            </span>
          </Input>
          <span class="lookupImg nowrap" @click="SeeFile">{{ form.licensing_name }}</span>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="医疗机构名称" :showIcon="true"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input v-model="form.medical_institution_name" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="医疗机构执业许可证"></FromLabel>
        <div class="formFrIpt fl">
          <i-input v-model="form.medical_institution_code" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="地址" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <!-- <Select @on-change="chooseProvince" v-model="form.province" class="iviewIptWidth165">
            <Option v-for="(item, index) in provinceList" :value="item.area_code" :key="index">{{ item.area_name }}</Option>
          </Select>
          <Select @on-change="chooseCity" v-model="form.city" class="iviewIptWidth165 marginLeft20">
            <Option v-for="(item, index) in cityList" :value="item.area_code" :key="index">{{ item.area_name }}</Option>
          </Select> -->
          <i-input class="iviewIptWidth307" v-model="form.detailed_address" placeholder="请输入详细地址"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="邮政编码"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input min="0" type="number" v-model="form.postal_code" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="所有制形式"></FromLabel>
        <div class="formFrIpt fl">
          <i-input v-model="form.ownership_type" placeholder="请输入所有制形式" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="医疗机构类型"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input v-model="form.medical_institution_type" placeholder="请输入医疗机构类型" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="经营性质"></FromLabel>
        <div class="formFrIpt fl">
          <i-input v-model="form.management_type" placeholder="请输入经营性质" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="服务对象"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input v-model="form.service_object" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="注册资金"></FromLabel>
        <div class="formFrIpt fl">
          <i-input v-model="form.registered_capital" placeholder="请输入" class="iviewIptWidth307"></i-input>
          <span class="afterWords10"> 万元</span>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="床位"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input v-model="form.bed_num" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="牙椅"></FromLabel>
        <div class="formFrIpt fl">
          <i-input v-model="form.dental_chair" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="法人"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input v-model="form.legal_person" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="主要负责人"></FromLabel>
        <div class="formFrIpt fl">
          <i-input v-model="form.main_resposible" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="有效期限" :disabled="longTerm" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <DatePicker @on-open-change="setTimet" @on-change="changeTime($event, 1)" :options="options2" :value="form.effective_start" format="yyyy-MM-dd" style="width: 150px;" type="date" placeholder="请选择"></DatePicker>
          <span class="marginWords10"> 至 </span>
          <DatePicker @on-change="changeTime($event, 2)" :options="options1" :value="form.effective_end" :disabled="longTerm" format="yyyy-MM-dd" style="width: 150px;" type="date" placeholder="请选择"></DatePicker>
          <Checkbox true-value="1" false-value="0" @on-change="LongTermChange" v-model="form.is_long_term" class="singleCheckBox">长期</Checkbox>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="登记号"></FromLabel>
        <div class="allWidth">
          <i-input v-model="form.register_code" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="诊疗科目"></FromLabel>
        <div class="allWidth">
          <i-input v-model="form.diagnosis_subject" placeholder="请输入" class="iviewIptWidth1143"></i-input>
        </div>
      </div>
      <div class="saveDiv clearfix">
        <div class="pageBtn finger btnSure fr marginLeft20" @click="nextstep1()">保存</div>
        <span class="pageBtn finger btnReset fr marginLeft20" @click="back">返回</span>
      </div>
      <Modal v-model="imgStatus1" title="执业证许可证" @on-ok="ok" @on-cancel="cancel">
        <div class="imgBox">
          <img :src="imgUrl1" alt="" />
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import FormUpload from '../../../components/formUpload'

export default {
  name: 'clientRegisteCerticate',
  components: {
    FromLabel,
    FormUpload,
  },
  data() {
    return {
      type: '',
      license: [],
      prolong: false,
      isLoad: false,
      // 控制现实图片对话框的现实与关闭
      imgStatus1: false,
      // 对话框中浏览图片的url地址
      longTerm: false,
      imgUrl1: '',
      total: 0,
      pages: {
        page: 1,
        rows: 10,
      },
      // 存储下一步提交的数据
      form: {
        hospital_id: null, //	医院ID
        licensing_id: null, // 执行许可id
        licensing_name: null, // 许可证名称
        licensing_key: null, // 执业许可证KEY
        medical_institution_name: null, // 医疗机构名称
        city: null, // 市级地址ID
        detailed_address: null, //	详情地址
        postal_code: null, //	邮政编码
        ownership_type: null, //	所有制形式
        management_type: null, //	经营性质
        medical_institution_type: null, // 医疗机构类别
        medical_institution_code: null,
        service_object: null, //	服务对象
        registered_capital: null, // 注册资金
        bed_num: null, // 床位
        dental_chair: null, // 牙椅
        legal_person: null, // 法人
        main_resposible: null, // 主要负责人
        effective_start: null, // 起始有效期限格式”0000-00-00“没填不传
        effective_end: null, // 截止有效期限格式”0000-00-00“没填不传
        register_code: null, // 登记号
        diagnosis_subject: null, // 诊疗科目
        is_long_term: '0', //	是否长期 0=否 1=是
      },
      // 存储省份地址
      provinceList: [],
      // 存放省份地址
      province: '',
      // 存放市区地址列表
      cityList: [],
      // 存放市区地址
      //
      hospital_id: '',
      options1: {},
      options2: {},
    }
  },
  created() {
    // this.getBelongArea()
  },
  mounted() {
    this.$root.eventHub.$off('sendhospitalID')
    this.$root.eventHub.$on('sendhospitalID', hospital_id => {
      this.hospital_id = hospital_id
      console.log(this.hospital_id, 'hospital_id')
    })
    this.$root.eventHub.$off('sendLicensingId')
    this.$root.eventHub.$on('sendLicensingId', licenseId => {
      // 待提交数据
      this.form.licensing_id = licenseId
      console.log(this.form.licensing_id)
      this.judge()
    })
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    setTimet() {
      let that = this
      that.options2 = {
        disabledDate(date) {
          return date && date.valueOf() > new Date(that.$moment().format('YYYY-MM-DD'))
        },
      }
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.form.effective_start = e
        that.form.effective_end = ''
        that.options1 = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.form.effective_start)
          },
        }
      } else {
        this.form.effective_end = e
      }
    },
    importTemplate() {},

    downloadTemplate() {},
    changePage(e) {},
    // 点击长期触发事件
    LongTermChange(e) {
      this.longTerm = !this.longTerm
      this.form.effective_end = ''
    },
    ok() {
      console.log('ok')
    },
    cancel() {
      console.log('cancel')
    },
    // 开始判断状态  是新增进入 还是直接进入
    judge() {
      if (this.$route.query.id !== undefined) {
        this.getLicense()
      } else {
        console.log('新增进入')
      }
    },
    // 查看
    SeeFile() {
      window.open(this.form.licensing_url)
    },
    // 上传按钮触发事件
    getKey(key, name, url) {
      this.form.licensing_key = key
      this.form.licensing_name = name
      this.form.licensing_url = url
    },

    // 获取列表执业许可列表
    getLicense() {
      let data = {
        hospital_id: this.$route.query.id,
        licensing_id: this.form.licensing_id,
      }
      console.log(data)
      if (data.licensing_id != null) {
        // 发送请求
        this.$http.get(this.$api.hospitalManageLicensing, data, true).then(res => {
          console.log(res)
          if (res.data.is_long_term == '1') {
            this.longTerm = true
          } else {
            this.longTerm = false
          }
          this.form = res.data
          // // 发送请求获取市级id
          // let data2 = {
          //   parent_id: this.form.province,
          // }
          // if (data2.parent_id != 0 && data2.parent_id != null) {
          //   this.$http.get(this.$api.zoneList, data2, true).then(res => {
          //     this.cityList = res.data
          //   })
          // }
          // 修改时间
          this.form.effective_start = this.$moment.unix(res.data.effective_start).format('YYYY-MM-DD')
          this.form.effective_end = this.$moment.unix(res.data.effective_end).format('YYYY-MM-DD')
          if (this.form.effective_start == '1970-01-01') {
            this.form.effective_start = ''
          }
          if (this.form.effective_end == '1970-01-01') {
            this.form.effective_end = ''
          }
        })
      }
    },

    // 上一步
    backstep() {
      console.log('返回')
      this.$emit('sendform', 1)
    },
    // 下一步
    nextstep1() {
      this.$parent.$refs.ClientProductApproval.gothrougthSave()
    },
    async nextstep(type) {
      let status1 = false
      // ---------添加验证规则--------
      if (this.form.medical_institution_name == '') {
        this.$Message.warning('请填写执业许可证页面的医疗机构名称')
        return
      }
      if (this.form.medical_institution_name == '') {
        this.$Message.warning('请填写执业许可证页面的医疗机构名称')
        return
      }
      // if (this.form.province == '' || this.form.province == null) {
      //   this.$Message.warning('请填写省份地址')
      //   return
      // }
      if (Number(this.form.postal_code) > 999999) {
        console.log(this.form.postal_code)
        this.$Message.warning('执业许可证页面的邮政编码必须在6位以内')
        return
      }
      // 如果长期
      if (this.longTerm) {
        this.form.is_long_term = '1'
        if (this.form.effective_start == '') {
          this.$Message.warning('请填写执业许可证页面的起始有效期限')
          return
        }
      }

      // 如果不长期
      if (!this.longTerm) {
        this.form.is_long_term = '0'
        if (this.form.effective_start == '') {
          this.$Message.warning('请填写执业许可证页面的起始有效期限')
          return
        }
        if (this.form.effective_end == '') {
          this.$Message.warning('请填写执业许可证页面的截止有效期限')
          return
        }
      }
      // ---------添加验证规则--------
      this.form.hospital_id = this.hospital_id || this.$route.query.id
      let data = this.form
      // return
      status1 = await this.$http
        .post(this.$api.hospitalManageLicensing, data, true)
        .then(res => {
          console.log(res)
          if (!type) {
            this.$Message.success('保存成功')
          }
          // this.$emit('sendform', 3)
          return true
        })
        .catch(err => {
          return false
        })
      sessionStorage.setItem('updataCache', '0')
      return status1
    },
  },
  watch: {},
}
</script>

<style scoped lang="less">
.content {
  height: 100%;
  .fromDiv {
    position: relative;
    height: 100%;
    .saveDiv {
      position: absolute;
      right: 0;
      bottom: 0;
      // margin-top: 20px;
      // float: right;
    }
  }
}
.imgBox {
  width: 200px;
  height: 200px;
  img {
    width: 100%;
    height: 100%;
  }
}

.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.marginRight18 {
  margin-right: 18px;
}
.tableTitle {
  margin: 17px 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.marginWords10,
.singleCheckBox {
  font-size: 14px;
  color: #525b6d;
  margin: 0 10px;
}
</style>
