<template>
  <div class="content">
    <!--  注册证 -->
    <div class="fromDiv">
      <div class="tableTitle">
        <span class="title"> 开票信息 </span>
      </div>
      <div class="formLine">
        <FromLabel label="开票抬头"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input v-model="form.invoice_title" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="税号"></FromLabel>
        <div class="formFrIpt fl">
          <i-input v-model="form.duty_paragraph" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="开户银行"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input v-model="form.deposit_bank" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="银行账号"></FromLabel>
        <div class="formFrIpt fl">
          <i-input v-model="form.bank_num" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="企业地址"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input v-model="form.business_address" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="企业电话"></FromLabel>
        <div class="formFrIpt fl">
          <i-input v-model="form.business_phone" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="tableTitle">
        <span class="title"> 联系人信息 </span>
      </div>
      <div class="formLine">
        <FromLabel label="姓名"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input v-model="form.name" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="证件号码"></FromLabel>
        <div class="formFrIpt fl">
          <i-input v-model="form.id_number" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="手机号"></FromLabel>
        <div class="formFlIpt647 fl">
          <i-input v-model="form.phone_number" placeholder="邮箱" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="邮箱"></FromLabel>
        <div class="formFrIpt fl">
          <i-input v-model="form.e_mail" placeholder="邮箱" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="saveDiv clearfix">
        <div class="pageBtn finger btnSure fr marginLeft20" @click="newNextStep()">保存</div>
        <span class="pageBtn finger btnReset fr marginLeft20" @click="back">返回</span>
      </div>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
export default {
  name: 'clientOtherMsg',
  components: {
    FromLabel,
  },
  data() {
    return {
      type: '',
      license: [],
      prolong: false,
      isLoad: false,
      total: 0,
      pages: {
        page: 1,
        rows: 10,
      },

      //
      form: {
        hospital_id: '', // 医院id
        supply_id: '', // 供应商id
        invoice_title: '', // 发票抬头
        duty_paragraph: '', // 税号
        deposit_bank: '', // 开户银行
        bank_num: '', // 银行账号
        business_address: '', // 企业地址
        business_phone: '', // 企业电话
        name: '',
        id_number: '',
        phone_number: '',
        e_mail: '',
      },
      hospital_id: '',
      // 橘色id
      // roleID:0,
    }
  },
  created() {},
  mounted() {
    this.$root.eventHub.$off('sendhospitalID2')
    this.$root.eventHub.$on('sendhospitalID2', hospital_id => {
      this.hospital_id = hospital_id
      console.log(this.hospital_id, '第三个')
    })
    this.$root.eventHub.$off('sendSupplyId')
    this.$root.eventHub.$on('sendSupplyId', supplyId => {
      // 待提交数据
      this.form.supply_id = supplyId
      console.log(this.form.supply_id)
      this.judge()
    })
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    changePage(e) {},
    importTemplate() {},
    downloadTemplate() {},

    // 开始判断状态  是新增进入 还是直接进入
    judge() {
      if (this.$route.query.id !== undefined) {
        this.getaddInform()
      } else {
        console.log('新增进入')
      }
    },

    // 获取医院补充信息
    getaddInform() {
      let data = {
        hospital_id: this.$route.query.id,
        supply_id: this.form.supply_id,
      }
      if (data.supply_id != null) {
        this.$http.get(this.$api.hospitalManageSupply, data, true).then(res => {
          console.log(res)
          this.form = res.data
        })
      }
    },
    // 上一步
    backstep() {
      console.log('返回')
      this.$emit('sendform', 2)
    },
    // 下一步
    newNextStep() {
      this.$parent.$refs.ClientProductApproval.gothrougthSave()
    },
    async nextstep(type) {
      let status1 = false
      this.form.hospital_id = this.hospital_id || this.$route.query.id
      let data = this.form
      console.log(data)
      status1 = await this.$http
        .post(this.$api.hospitalManageSupply, data, true)
        .then(res => {
          console.log(res)
          // 穿出
          this.$root.eventHub.$emit('sendRoleId', res.data.role_id)
          if (!type) {
            this.$Message.success('保存成功')
          }
          // this.$emit('sendform', 4)
          return true
        })
        .catch(err => {
          return false
        })
      sessionStorage.setItem('updataCache', '0')
      return status1
    },
  },
}
</script>

<style scoped lang="less">
.content {
  height: 100%;
  .fromDiv {
    height: 100%;
    position: relative;
    .saveDiv {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.marginRight18 {
  margin-right: 18px;
}
.tableTitle {
  margin: 17px 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
</style>
