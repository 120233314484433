<template>
  <div class="pagePadding">
    <ul class="topNavDiv">
      <li v-show="item.isshow" v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}
        <div class="linear" v-if="clickIndex == item.type"></div>
      </li>
    </ul>
    <div class="btmDiv">
      <div class="formDiv">
        <!-- 客户信息  -->
        <ClientDealerInfo ref="ClientDealerInfo" @sendhospitalID="gethospitalId($event)" @sendform="getform($event)" v-show="clickIndex == 1"></ClientDealerInfo>
        <!-- 执业证许可证 -->
        <ClientRegisteCerticate ref="ClientRegisteCerticate" @sendform="getform($event)" v-show="clickIndex == 2"></ClientRegisteCerticate>
        <!-- 补充信息 -->
        <ClientOtherMsg ref="ClientOtherMsg" @sendRoleId="getRoleID($event)" @sendform="getform($event)" v-show="clickIndex == 3"></ClientOtherMsg>
        <!-- 首印审批 -->
        <ClientProductApproval ref="ClientProductApproval" :roleId="roleId" v-show="clickIndex == 4"></ClientProductApproval>
      </div>
    </div>
  </div>
</template>

<script>
import ClientRegisteCerticate from './clientRegisteCerticate'
import ClientOtherMsg from './clientOtherMsg'
import ClientDealerInfo from './clientDealerInfo'
import ClientProductApproval from './clientProductApproval'

export default {
  name: 'clientManageFrame',
  components: {
    ClientRegisteCerticate,
    ClientOtherMsg,
    ClientDealerInfo,
    ClientProductApproval,
  },
  data() {
    return {
      navFrist: [
        {
          name: '客户信息',
          type: 1,
          isshow: true,
        },
        {
          name: '执业许可证',
          type: 2,
          isshow: false,
        },
        {
          name: '补充信息',
          type: 3,
          isshow: false,
        },
        {
          name: '首营审批',
          type: 4,
          isshow: false,
        },
      ],
      clickIndex: 1,
      // 用来存储目前进行到的步骤
      currentIndex: 1,
      hospitalid: '',
      roleId: 0,
    }
  },
  created() {
    this.ClickIn()
  },
  methods: {
    ClickIn() {
      if (this.$route.query.id) {
        this.navFrist.forEach((item, index) => {
          item.isshow = true
        })
      }
    },
    clickNav(type) {
      this.clickIndex = type
      // if (type <= this.currentIndex) {
      // }
    },
    getform(data) {
      console.log(data)
      if (data == '2') {
        this.navFrist.forEach((item, index) => {
          item.isshow = true
        })
      }
      this.clickIndex = data
      this.currentIndex = data
      this.navFrist.forEach((item, index) => {
        item.isshow = true
      })
    },

    // 获取医院id
    gethospitalId(data) {
      this.hospitalid = data
    },
    getRoleID(data) {
      this.roleId = data
    },
  },
}
</script>

<style scoped lang="less">
.pagePadding {
  padding: 0 !important;
  background: #f0f0f0 !important;
  height: 100px;
  display: flex;
  flex-direction: column;
  .btmDiv {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 0px 25px 30px 25px;
    overflow-y: auto;
    .formDiv {
      flex: 1;
      margin-bottom: 30px;
    }
    .saveDiv {
      height: 36px;
    }
  }
  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 120px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
</style>
